var contentController = {
    sendFormEmail: function (divId, statusDiv, successMessage) {
        var context = $('#' + divId);
        var nodes = $('input.required:not([type=file]):not([type=submit]):not([type=image]):not([type=reset]):not([type=button]), select.required, textarea.required', context).filter(':visible');
        nodes = nodes.filter(function (i) {
            return !this.disabled && this.name;
        });
        var validator = new Validator();
        var validateItemOptions = {
            customParent: true,
            parent: context
        };
        var node,
            validateItem;
        for (var i = 0; i < nodes.length; i++) {
            node = nodes[i];

            if (node.type === 'email') {
                validateItem = newEmailValidateItem(node.name, node.name, validateItemOptions);
            } else {
                validateItem = newValidateItem(node.name, node.name, validateItemOptions);
            }

            validator.addItem(validateItem);
        }
        if (validator.isValid()) {
            var content = this.generateFormEmailContent(divId);
            this.sendFormEmailContent(divId, statusDiv, content, successMessage);
        }
    },
    generateFormEmailContent: function (divId) {
        var nodes = $('input:not([type=file]):not([type=submit]):not([type=image]):not([type=reset]):not([type=button]), select, textarea', $('#' + divId));
        nodes = nodes.filter(function (i) {
            return !this.disabled && this.name;
        });
        var content = '';
        content += '<table>\n';
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            content += '<tr>';
            content += '<td>';
            content += node.name;
            content += '</td>';
            content += '<td>';
            var type = (node.type || '').toLowerCase();
            if (type == 'radio' || type == 'checkbox') {
                if (node.checked) {
                    content += node.value;
                }
            } else if (node.multiple) {
                var options = $('option', node);
                for (var j = 0; j < options.length; j++) {
                    var option = options[j];
                    if (option.selected) {
                        content += option.value;
                        content += '<br />';
                    }
                }
            } else {
                if (node.nodeName.toLowerCase() === 'textarea') {
                    content += node.value.replace(/\r\n|\r|\n/g, '<br />');
                } else {
                    content += node.value;
                }
            }
            content += '</td>';
            content += '</tr>\n';
        }
        content += '<tr><td>username</td><td>';
        content += globalpageglobals.cuser == null ? 'not logged in' : globalpageglobals.cuser.username;
        content += '</td></tr>\n';
        content += '<tr><td>first name</td><td>';
        content += globalpageglobals.cuser == null ? '' : globalpageglobals.cuser.fname;
        content += '</td></tr>\n';
        content += '<tr><td>last name</td><td>';
        content += globalpageglobals.cuser == null ? '' : globalpageglobals.cuser.lname;
        content += '</td></tr>\n';
        content += '<tr><td>email</td><td>';
        content += globalpageglobals.cuser == null ? '' : globalpageglobals.cuser.email;
        content += '</td></tr>\n';
        content += '<tr><td>phone</td><td>';
        content += globalpageglobals.cuser == null ? '' : globalpageglobals.cuser.phone;
        content += '</td></tr>\n';
        content += '</table>\n';

        // append the refering page
        var table = new JDTable();
        table.addRow(['Referrer', document.referrer]);
        content += table.generate();
        return content;
    },
    sendFormEmailContent: function (formName, statusDiv, content, successMessage) {
        var cometRequest = comet.newRequest(contentPageGlobals.formInputHandlerURL, 'SENDEMAILACTION', function (cometRequest) {
            growlMessages.showAll(cometRequest);
            if (!cometRequest.hasErrorMessages()) {
                dynamic.setContentSmooth2(formName, '');
                events.trigger(events.FORM_EMAIL_SENT);
                events.trigger(getCustomEmailFormSentEventName(formName));
            } else {
                events.trigger(events.FORM_EMAIL_ERROR);
            }
        });
        cometRequest.statusDivId = statusDiv;
        cometRequest.addParameter('FORMNAMEID', formName);
        cometRequest.addParameter('EMAILCONTENT', content);
        cometRequest.addParameter('SUCCESS_MESSAGE', successMessage);
        cometRequest.send();
    }
};
function sendFormEmail (formName, statusDiv) {
    contentController.sendFormEmail(formName, statusDiv, 'Your request has been sent.');
}
function sendFormEmailNR (formName, statusDiv) {
    contentController.sendFormEmail(formName, statusDiv, null);
}
